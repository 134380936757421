<script lang="ts">
  import {
    AUDIO,
    BADGES,
    BADGE_AWARD,
    BADGE_DEFINITION,
    BLOCKED_RELAYS,
    BOOKMARKS,
    CALENDAR,
    CHANNELS,
    CLASSIFIED,
    COMMENT,
    COMMUNITIES,
    COMMUNITY,
    DVM_FEEDBACK,
    DVM_RESPONSE_COUNT,
    DVM_RESPONSE_DISCOVER_CONTENT,
    DVM_RESPONSE_DISCOVER_PEOPLE,
    DVM_RESPONSE_IMAGE_GENERATION,
    DVM_RESPONSE_IMAGE_TO_VIDEO_CONVERSION,
    DVM_RESPONSE_MALWARE_SCAN,
    DVM_RESPONSE_OP_RETURN,
    DVM_RESPONSE_OTS,
    DVM_RESPONSE_PUBLISH_SCHEDULE,
    DVM_RESPONSE_SEARCH_CONTENT,
    DVM_RESPONSE_SEARCH_PEOPLE,
    DVM_RESPONSE_TEXT_EXTRACTION,
    DVM_RESPONSE_TEXT_GENERATION,
    DVM_RESPONSE_TEXT_SUMMARY,
    DVM_RESPONSE_TEXT_TO_SPEECH,
    DVM_RESPONSE_TEXT_TRANSLATION,
    DVM_RESPONSE_VIDEO_CONVERSION,
    DVM_RESPONSE_VIDEO_TRANSLATION,
    EMOJIS,
    EVENT_DATE,
    EVENT_RSVP,
    EVENT_TIME,
    FEED,
    FEEDS,
    FILE_METADATA,
    FILE_SERVERS,
    FOLLOWS,
    GENERIC_REPOST,
    GIT_ISSUE,
    GIT_PATCH,
    GIT_REPLY,
    GIT_REPOSITORY,
    GROUPS,
    GROUP_ADMINS,
    GROUP_META,
    HANDLER_INFORMATION,
    HANDLER_RECOMMENDATION,
    HIGHLIGHT,
    INBOX_RELAYS,
    LABEL,
    LIVE_CHAT_MESSAGE,
    LIVE_EVENT,
    LONG_FORM,
    MESSAGE,
    MUTES,
    NAMED_ARTIFACTS,
    NAMED_BOOKMARKS,
    NAMED_COMMUNITIES,
    NAMED_CURATIONS,
    NAMED_EMOJIS,
    NAMED_PEOPLE,
    NAMED_RELAYS,
    NAMED_TOPICS,
    NAMED_WIKI_AUTHORS,
    NAMED_WIKI_RELAYS,
    NOSTROCKET_PROBLEM,
    NOTE,
    PICTURE_NOTE,
    PINS,
    PROFILE,
    REACTION,
    RELAYS,
    REMIX,
    REPORT,
    REPOST,
    REVIEW,
    SEARCH_RELAYS,
    STATUS,
    THREAD,
    TOPICS,
    WIKI,
    ZAP_GOAL,
    ZAP_RESPONSE,
  } from "@welshman/util"
  import {FeedType} from "@welshman/feeds"
  import SearchSelect from "src/partials/SearchSelect.svelte"
  import {KindSearch} from "src/domain"

  export let feed
  export let onChange

  const onKindsChange = kinds => onChange([FeedType.Kind, ...kinds])

  const termToItem = (s: string) => parseInt(s)

  const termIsValid = (s: string) => !isNaN(parseInt(s))

  const helper = new KindSearch([
    {label: "Note", kind: NOTE},
    {label: "Profile", kind: PROFILE},
    {label: "Reaction", kind: REACTION},
    {label: "Live chat", kind: LIVE_CHAT_MESSAGE},
    {label: "Picture", kind: PICTURE_NOTE},
    {label: "Remix", kind: REMIX},
    {label: "Audio", kind: AUDIO},
    {label: "Report", kind: REPORT},
    {label: "Label", kind: LABEL},
    {label: "Review", kind: REVIEW},
    {label: "Highlight", kind: HIGHLIGHT},
    {label: "Long form article", kind: LONG_FORM},
    {label: "Live event", kind: LIVE_EVENT},
    {label: "Status", kind: STATUS},
    {label: "Classified Listing", kind: CLASSIFIED},
    {label: "Calendar event (date based)", kind: EVENT_DATE},
    {label: "Calendar event (time based)", kind: EVENT_TIME},
    {label: "Calendar event RSVP", kind: EVENT_RSVP},
    {label: "Handler recommendation", kind: HANDLER_RECOMMENDATION},
    {label: "Handler information", kind: HANDLER_INFORMATION},
    {label: "Image", kind: FILE_METADATA},
    {label: "Relay selections", kind: RELAYS},
    {label: "Nostrocket Problem", kind: NOSTROCKET_PROBLEM},
    {label: "User Badges", kind: BADGES},
    {label: "Badge Award", kind: BADGE_AWARD},
    {label: "Badge Definition", kind: BADGE_DEFINITION},
    {label: "Blocked Relays", kind: BLOCKED_RELAYS},
    {label: "Bookmarks", kind: BOOKMARKS},
    {label: "Calendar", kind: CALENDAR},
    {label: "Channels", kind: CHANNELS},
    {label: "Comment", kind: COMMENT},
    {label: "Community Membership", kind: COMMUNITIES},
    {label: "Community Definition", kind: COMMUNITY},
    {label: "DVM Feedback", kind: DVM_FEEDBACK},
    {label: "DVM Response (Count)", kind: DVM_RESPONSE_COUNT},
    {label: "DVM Response (Discover Content)", kind: DVM_RESPONSE_DISCOVER_CONTENT},
    {label: "DVM Response (Discover People)", kind: DVM_RESPONSE_DISCOVER_PEOPLE},
    {label: "DVM Response (Image Generation)", kind: DVM_RESPONSE_IMAGE_GENERATION},
    {
      label: "DVM Response (Image to Video Conversion)",
      kind: DVM_RESPONSE_IMAGE_TO_VIDEO_CONVERSION,
    },
    {label: "DVM Response (Malware Scan)", kind: DVM_RESPONSE_MALWARE_SCAN},
    {label: "DVM Response (OP_RETURN)", kind: DVM_RESPONSE_OP_RETURN},
    {label: "DVM Response (Open Timestamp)", kind: DVM_RESPONSE_OTS},
    {label: "DVM Response (Publish Schedule)", kind: DVM_RESPONSE_PUBLISH_SCHEDULE},
    {label: "DVM Response (Search Content)", kind: DVM_RESPONSE_SEARCH_CONTENT},
    {label: "DVM Response (Search People)", kind: DVM_RESPONSE_SEARCH_PEOPLE},
    {label: "DVM Response (Text Extraction)", kind: DVM_RESPONSE_TEXT_EXTRACTION},
    {label: "DVM Response (Text Generation)", kind: DVM_RESPONSE_TEXT_GENERATION},
    {label: "DVM Response (Text Summary)", kind: DVM_RESPONSE_TEXT_SUMMARY},
    {label: "DVM Response (Text to Speech)", kind: DVM_RESPONSE_TEXT_TO_SPEECH},
    {label: "DVM Response (Text Translation)", kind: DVM_RESPONSE_TEXT_TRANSLATION},
    {label: "DVM Response (Video Conversion)", kind: DVM_RESPONSE_VIDEO_CONVERSION},
    {label: "DVM Response (Video Translation)", kind: DVM_RESPONSE_VIDEO_TRANSLATION},
    {label: "Emoji Pack", kind: EMOJIS},
    {label: "Custom Feed", kind: FEED},
    {label: "Bookmarked Feeds", kind: FEEDS},
    {label: "File Server Selections", kind: FILE_SERVERS},
    {label: "Follows", kind: FOLLOWS},
    {label: "Generic Repost", kind: GENERIC_REPOST},
    {label: "Git Issue", kind: GIT_ISSUE},
    {label: "Git Patch", kind: GIT_PATCH},
    {label: "Git Reply", kind: GIT_REPLY},
    {label: "Repository", kind: GIT_REPOSITORY},
    {label: "Group Memberships", kind: GROUPS},
    {label: "Group Admins", kind: GROUP_ADMINS},
    {label: "Group Definition", kind: GROUP_META},
    {label: "Inbox Relays", kind: INBOX_RELAYS},
    {label: "Chat Message", kind: MESSAGE},
    {label: "Mute List", kind: MUTES},
    {label: "Artifact List", kind: NAMED_ARTIFACTS},
    {label: "Bookmark List", kind: NAMED_BOOKMARKS},
    {label: "Community List", kind: NAMED_COMMUNITIES},
    {label: "Curations", kind: NAMED_CURATIONS},
    {label: "Emoji List", kind: NAMED_EMOJIS},
    {label: "Person List", kind: NAMED_PEOPLE},
    {label: "Relay List", kind: NAMED_RELAYS},
    {label: "Topic List", kind: NAMED_TOPICS},
    {label: "Wiki Authors", kind: NAMED_WIKI_AUTHORS},
    {label: "Wiki Relays", kind: NAMED_WIKI_RELAYS},
    {label: "Pins", kind: PINS},
    {label: "Repost", kind: REPOST},
    {label: "Search Relay Selections", kind: SEARCH_RELAYS},
    {label: "Thread", kind: THREAD},
    {label: "Topic List", kind: TOPICS},
    {label: "Wiki Article", kind: WIKI},
    {label: "Zap Goal", kind: ZAP_GOAL},
    {label: "Zap", kind: ZAP_RESPONSE},
  ])
</script>

<span class="staatliches text-lg">What kind of content do you want to see?</span>
<SearchSelect
  multiple
  search={helper.searchValues}
  value={feed.slice(1)}
  onChange={onKindsChange}
  {termToItem}
  {termIsValid}>
  <div slot="item" let:item>{helper.displayValue(item)}</div>
</SearchSelect>
